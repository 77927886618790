@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *:focus {
    outline: none;
  }

  :root {
    --text-primary: 41, 37, 36;
    --bg-primary: 243, 244, 246;
    --bg-secondary: 229, 231, 235;
    --bg-tertiary: 209, 213, 219;
  }

  .dark {
    --text-primary: 255, 255, 255;
    --bg-primary: 17, 24, 39;
    --bg-secondary: 31, 41, 55;
    --bg-tertiary: 55, 65, 81;
  }

  .taelor-bottom-menu {
    @apply rounded-full;
    transition: background 0.8s;
  }

  .taelor-bottom-menu:hover {
    @apply bg-taelor-700/30;
  }

  .taelor-bottom-menu.menu-active {
    @apply bg-taelor-blue/40;
  }

  .taelor-bottom-menu.menu-active svg>path {
    fill: theme("colors.taelor.blue");
  }

  @keyframes skeleton-loading {
    0% {
      background-color: theme("colors.taelor.700/30");
    }

    100% {
      background-color: theme("colors.taelor.800/95");
    }
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .skeleton-text {
    width: 100%;
    height: 1.25rem;
    border-radius: 0.25rem;
  }

  .skeleton-text:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .divider {
    @apply w-full;
    @apply p-[1px];
    @apply bg-taelor-800;
  }

  .rename-chat-container:hover .rename-chat-icon {
    display: block;
  }

  .assistant-msg table {
    @apply my-2;
    @apply max-w-fit;
    border-collapse: separate;
    display: block;
    /* margin: 0 auto; */
    overflow-x: auto;
    white-space: nowrap;
  }

  .assistant-msg th {
    @apply text-taelor-50;
    @apply font-medium;
    /* background-color: rgba(236, 236, 241, 0.2); */
    @apply bg-taelor-700;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-width: 1px;
    padding: 0.25rem 0.75rem;
  }

  .assistant-msg th:first-child {
    border-top-left-radius: 0.375rem;
  }

  .assistant-msg th:last-child {
    border-right-width: 1px;
    border-top-right-radius: 0.375rem;
  }

  .assistant-msg td {
    border-bottom-width: 1px;
    border-left-width: 1px;
    padding: 0.25rem 0.75rem;
  }

  .assistant-msg td:last-child {
    border-right-width: 1px;
  }

  .assistant-msg tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0.375rem;
  }

  .assistant-msg tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.375rem;
  }

  .assistant-msg ul {
    list-style-type: disc;
    @apply my-2;
  }

  .assistant-msg ul li {
    @apply ml-5;
  }

  .feedback-btn {
    @apply mr-4;
  }

  .feedback-btn:hover {
    @apply text-taelor-50;
    @apply cursor-pointer;
  }

  .feedback-btn.selected {
    @apply text-taelor-50;
  }
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #DEE4EA);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn)
  }
}

.home-text-gradient {
  background-color: #9dbff0;
  background-image: linear-gradient(45deg, #9dbff0, #7aacf3);
  background-size: 100%;
  background-clip: text;
  font-style: italic;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
