.swiper {
  margin-top: auto;
  margin-right: 0;
  margin-left: 0;
  overflow: visible;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    overflow: unset;
    padding: 18px;
  }
}

.swiper-wrapper {
  gap: 16px;

  @media screen and (min-width: 600px) {
    display: flex !important;
    justify-content: space-around !important;
  }
}

.swiper-slide {
  width: 260px !important;

  @media screen and (min-width: 1024px) {
    width: 320px !important;
  }
}
