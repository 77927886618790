.swiper-skill {
  overflow: visible;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    overflow: unset;
    padding: 18px;
  }
}

.wrapper-skill {
  @media screen and (min-width: 600px) {
    display: flex;
    justify-content: center !important;
  }
}

.slide-skill {
  display: flex;
  justify-content: center;
  width: 160px !important;
}

.skill-bg {
  background: linear-gradient(329deg, rgba(24, 43, 77, 0.97) 2.49%, rgba(9, 30, 66, 0.33) 45.73%, rgba(64, 84, 120, 0.46) 72.57%, #71777B 94.09%), var(--Dark-mode-Neutral-Dark-neutral-0, #161A1D);
}

.skill-bg-image {
  background: linear-gradient(325deg, #05C 10.4%, rgba(6, 67, 171, 0.33) 47.32%, rgba(34, 80, 130, 0.40) 66.47%, #CCE0FF 88%), url('/public/onboarding/bg-skill.png'), lightgray 50% / cover no-repeat;
  background-size: cover;
  box-shadow: 3px 7px 97.8px 0px #091E42, 0px 4px 11.9px 0px rgba(15, 21, 33, 0.40);
}
